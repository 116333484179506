.landingfooter {
    margin-top: 80px;
	background-color: black;
	border-top: 1px solid #757474;
	text-align: center;
	padding: 40px;
	padding-top: 80px;
}


.footerLink {
	font-weight: 700;
	color: #fff;
    font-size: 20px;
    line-height: 1.4;
    line-height: 2;
    padding-bottom: 8px;
}

.bottomLinks {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	font-size: 12px;
    line-height: 21px;
    color: #999;

    margin-left: auto;
    margin-right: auto;
}

.LinkStyle{
	margin: auto 26px auto 0;
    color: #fff;
    font-size: 20px;
    line-height: 1.4;
}
