body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #DBDBDB !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: 'basis';
  src: local('basis'), url(./fonts/basis.otf) format('truetype');
} */

@font-face {
  font-family: 'basis';
  src: url(./fonts/basis.otf),
       url(./fonts/basis.otf) format('truetype');
}


/*
a:hover {
  color: #fa923f !important;
}*/

h1 {
  font-size: 2.075em !important;
  font-family: basis;
  text-decoration: none;
  font-weight: bolder;
}

.bigTitle{
  font-size: 10rem !important;
}

.inputfield {
    background-color: #bcbcbc !important;
    font-size: 1.5rem !important;
    border: 3px solid #000000 !important;
}

.phoneReport {
  margin-top: 0px;
}

.baseStyle {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.alin {
  margin-top: 80px;
  align-items: center;
  margin-bottom: 40px;
}

.alignLeft {
  text-align: left!important;
}

.bigBox {
  border: 2px solid rgb(0 0 0);
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.bigBox:hover {
  background-color: #ffaf6e !important;
}


.testButton {
    background-color: black !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 20px !important;
    font-weight: 550 !important;
}

.testButton:hover {
  background-color: #3e3e3e !important;
  border-color: #3e3e3e !important;
}

.submitButton {
    background-color: black !important;
    padding-top: 25px !important;
    padding-bottom: 20px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    margin-top: 30px !important;
    font-weight: 550 !important;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
    margin-top: 5px !important;
}

.custom-switch .custom-control-label::after {
    margin-top: 5px !important;
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.submitButton:hover {

  background-color: #3e3e3e !important;
  border-color: #3e3e3e !important;
}

@media (max-width: 740px) {
  .phoneReport {
    margin-top: 36px;
  }

  .bigTitle{
      font-size: 3rem !important;
  }
}