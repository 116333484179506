.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* background: #521751; */
  height: 187px;
}

.toolbar__navigation {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 2.075em;
  font-family: basis;
  text-decoration: none;
  font-weight: bolder;
}


.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo:hover {
  color: #fa923f;
}


.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.toolbar_navigation-items li {
  margin-left: 90px;
}

.toolbar_navigation-items a {
  /* color: black;
  font-size: 3.075em;
  font-family: basis; */
  text-decoration: none;
  color: black;
  /* font-weight: bolder; */
}

.social-items {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
}

.social {
  max-width: 40px;
  height: 40px;
  margin-right: 10px;
}

.coffee {
  width: 40px;
  height: 38px;
  background: url("../../img/coffeewhite.png");
  background-size: contain;
}

.coffeehover {
  width: 40px;
  height: 38px;
  background: url("../../img/coffeehover.png");
  background-size: contain;
}

.facebook {
  width: 40px;
  height: 38px;
  background: url("../../img/facebook.png");
  background-size: contain;
}

.facebook:hover {
  width: 40px;
  height: 38px;
  background: url("../../img/fborange.png");
  background-size: contain;
}

.instagram {
  width: 40px;
  height: 40px;
  background: url("../../img/instagram.png");
  background-size: contain;
}

.instagram:hover {
  width: 40px;
  height: 40px;
  background: url("../../img/instagramorange.png");
  background-size: contain;
}

.linkedin {
  width: 40px;
  height: 40px;
  background: url("../../img/linkedin.png");
  background-size: contain;
}

.linkedin:hover {
  width: 40px;
  height: 40px;
  background: url("../../img/linkedorange.png");
  background-size: contain;
}

.youtube {
  width: 40px;
  height: 40px;
  background: url("../../img/youtube.png");
  background-size: contain;
}

.youtube:hover {
  width: 40px;
  height: 40px;
  background: url("../../img/youtubeorange.png");
  background-size: contain;
}

.si {
  padding: 0 0.5rem !important;
  margin-left: 5px !important;
}


.spacer {
  flex: 1;
}


.toolbar_navigation-items ol {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}


.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #fa923f;
}

@media (max-width: 1160px) {
  .toolbar_navigation-items {
    display: none;
  }

  .toolbar__navigation{
    margin-left: 0px;
    margin-right: 0px;
    padding: 0 1rem;
  }
}

@media (max-width: 1468px) {
  .toolbar_navigation-items li {
    margin-left: 50px;
  }
}

@media (min-width: 1160px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}

